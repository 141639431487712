import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useEffect, useState } from "react";
import awsconfig from "../aws-exports";
import Amplify from "aws-amplify";
import { Auth } from 'aws-amplify';
import { Loading } from ".";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

Amplify.configure(awsconfig);

const Profile = withAuth0(() => {
  const classes = useStyles();
  const [NPI, setNPI] = useState(null);
  const [name, setName] = useState(null);
  const [title, setTitle] = useState(null);
  const [email, setEmail] = useState(null);
  const [location, setLocation] = useState(null);
  const [validNPI, setValidNPI] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showData, setShowData] = useState(false); 
  const [checkNPI, setCheckNPI] = useState(false);

  async function updateNPI(new_npi, validNPI, doctor_email) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const updateResponse = await fetch(`${apiUrl}/updatenpi/${doctor_email}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: doctor_email,
        new_npi: new_npi,
        valid_npi: validNPI
      }),
    });
    
    console.log(updateResponse);
  }

  useEffect(() => {
    let email = Auth.user.username;
    const fetchData = async () => {
      setEmail(email);
      const apiUrl = process.env.REACT_APP_API_URL;
      const npiResponse = await fetch(`${apiUrl}/getnpi/${email}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      return npiResponse.json();
    }
    fetchData()
      .then((data)=> 
      {   
        let valid_npi = data[0]["validNPI"];
        let npi = data[0]["NPI"];
        setNPI(npi);
        if(valid_npi){
          setCheckNPI({npi:npi});
        }
        else{
          setShowSearch(true);
          setShowData(false);
        }
  
      });
  }, []);

  useEffect(() => {
    if(!checkNPI){
      return;
    }

    const fetchData = async () => {
      setNPI(setCheckNPI.npi)
      const len = Math.ceil(Math.log10(NPI));
      if (len === 10){
        const fetchData = async () => { 
          const apiUrl = process.env.REACT_APP_API_URL;
          const npiResponse = await fetch(`${apiUrl}/npilookup/?number=${NPI}&version=2.1`);
          return npiResponse.json();
        }
        fetchData()
          .then((result) => {
            if(result["result_count"] === 1)
            {
              console.log(result["results"])
              console.log(result["results"][0]["basic"])

              setValidNPI(true);
              setNPI(NPI)
              setName(result["results"][0]["basic"]["name"]);
              setTitle(result["results"][0]["basic"]["authorized_official_credential"] || result["results"][0]["basic"]["credential"]);
              setLocation(result["results"][0]["taxonomies"][0]["state"]);
              updateNPI(NPI, true, email);
              setShowSearch(false);
              setShowData(true); 
            }
            else{
              setValidNPI(false);
              setShowSearch(true);
              alert("Invalid NPI, please try again.")
            }
          });
      }
      else{
        setValidNPI(false);
        setShowSearch(true);
        alert("Invalid NPI")
      }
    
    }

    fetchData();
  }, [checkNPI]);
  
  if (!showData && !showSearch){
      return (
        <Loading />
      )
  } 

  else{
      return (
        <>
          <Container className={classes.container}>
            <Typography variant="h4">Your Profile</Typography>
            &nbsp;
            {showSearch && (
                <Grid container spacing={1}>
                <Grid item xs={2} id="NPI">
                    <TextField 
                    id="standard-basic"
                    label="Enter Your NPI"
                    onChange={(e) => setNPI(e.target.value)} />    
                </Grid>
                <Grid item style={{textAlign: 'right'}} xs={1}>
                    <Button 
                    variant='contained' 
                    style={{marginTop: '16px'}} 
                    onClick={() => setCheckNPI({npi:NPI})}>
                    Verify
                    </Button>
                </Grid>
                </Grid>
            )}
          </Container>
          {showData && (
            <Container>
                <Typography variant="h6"><b>NPI: </b>{NPI}</Typography>
                <br/>
                <Typography variant="h6"><b>Valid NPI: </b>{validNPI.toString().toUpperCase()}</Typography>
                <br/>
                <Typography variant="h6"><b>Name: </b>{name}</Typography>
                <br/>
                <Typography variant="h6"><b>Title: </b>{title}</Typography>
                <br/>
                <Typography variant="h6"><b>Location: </b>{location}</Typography>
            </Container>     
          )}
        </>
    );
  }

});

export default Profile;
