import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
  ButtonGroup
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useEffect, useState } from "react";
import { Loading } from ".";
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const Patients = withAuth0(() => {
  const classes = useStyles();
  const [ makeRoleRequest, setRoleRequest ] = useState(null);
  const [userRole, setUserRole] = useState(null);  
  const [data, setData] = useState("");
  const [gotData, setGotData] = useState(false);
  const [NPI, setNPI] = useState(null);

  const fetchDoctorData = async (npi) => {
    if(npi){
      const apiUrl = process.env.REACT_APP_API_URL;
      const npiResponse = await fetch(`${apiUrl}/doctorpatients/${npi}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      return npiResponse.json();
    }
    return null;
  }
  
  const fetchAdminData = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const npiResponse = await fetch(`${apiUrl}/patients`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    console.log(npiResponse)
    return npiResponse.json();
  }

  useEffect(() => {
    let role = (Auth.user.signInUserSession.idToken.payload["cognito:groups"]).toString();
    console.log(role)
    setUserRole(role);

    if(!makeRoleRequest){
      return;
    }

    const fetchData = async () => {
      let changeRole;
      let newRole;
      const apiUrl = process.env.REACT_APP_API_URL;

      if (makeRoleRequest.role === "Doctor"){
          console.log("set role request: " + makeRoleRequest.role)
          changeRole = "doctors";
          newRole = {doctor: true};
        }
      else{
          console.log("set role request: " + makeRoleRequest.role)
          changeRole = "admins";
          newRole = {admin: true};
      }

      const response = await fetch(`${apiUrl}/${changeRole}/${makeRoleRequest.username}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newRole),
      });
      
      console.log(response);

      setRoleRequest(null);
    }

    fetchData()
      .then(() => {
        fetchAdminData()   
          .then((result)=> {
            setData(result)
            setGotData(true)  
          });
      });

  }, [makeRoleRequest]);

  useEffect(() => {
    
    if(!setData){
      return;
    }
    
    let role = (Auth.user.signInUserSession.idToken.payload["cognito:groups"]).toString()
    let email = Auth.user.username;
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const npiResponse = await fetch(`${apiUrl}/getnpi/${email}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      return npiResponse.json();
    }
    fetchData()
      .then((email_result)=> {   
        if(role === "Doctor"){
          let valid_npi = email_result[0]["validNPI"] || null;
          let npi = email_result[0]["NPI"];
          fetchDoctorData(npi)
            .then((result) => {
              if(valid_npi){
              setNPI(npi)
              setData(result)
              setGotData(true)
              }
              else {
                setData(null)
                setGotData(true)
              }
              console.log(result)
              });
        }
        else if(role === "Admin"){
          fetchAdminData()
            .then((result)=> { 
            console.log(result)
            setData(result)
            setGotData(true)  
          });
        }
      });     
  }, [setData]);

  if ((!gotData) || makeRoleRequest) {
    return <Loading />;
  }

  if(userRole === "Admin") { //If user is a doctor, display on their patients and the device data
    return (
      <>
        <Container className={classes.container}>
          <Typography variant="h4">Patients</Typography>
          &nbsp;
          <Grid container spacing={1}>    
            {data && data.sort((a, b) => a.email - b.email).map(patient => {
              return <React.Fragment key={patient.email}>
                <Grid item xs={4}>
                  <Typography variant="body1" style={{
                    marginTop: '20px'
                  }}>
                    {patient.email}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'right'}}>
                  <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button href={`/prescriptions/${patient.username}`}>
                      View Prescriptions
                    </Button>
                    <Button href={`/sessions/${patient.username}`}>
                      View Sessions
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'right'}}>
                  <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button onClick={() => {
                      setRoleRequest({
                        username: patient.username,
                        role: "Admin"
                      })
                    }}>
                      Make Admin
                    </Button>
                    <Button onClick={() => {
                      setRoleRequest({
                        username: patient.username,
                        role: "Doctor"
                      })
                    }}>
                      Make Doctor
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>                
              </React.Fragment>;
            })}
          </Grid>
        </Container>
      </>
    );
  }
  else{ //Doctors
    return (
      <>
        <Container className={classes.container}>
          <Typography variant="h4">Patients</Typography>
          &nbsp;
          <Grid container spacing={1}>          
            {data && data.sort((a, b) => a.email - b.email).map(patient => {
              return <React.Fragment key={patient.email}>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{
                    marginTop: '20px'
                  }}>
                    {patient.email}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}> 
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Button href={`/prescriptions/${patient.username}`}>
                    View Prescriptions
                  </Button>
                  <Button href={`/sessions/${patient.username}`}>
                    View Sessions
                  </Button>
                </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>    
              </React.Fragment>;
            })}
          </Grid>
        </Container>
      </>
    );
  }
});

export default Patients;
