import React, { Component } from "react";
import CustomSignUp from "./CustomSignUp";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {Auth} from 'aws-amplify';

const is_prod = process.env.REACT_APP_IS_PROD === 'true';

const theme_blue = {
  light: "#ffffff",
  main: "#2c2c34", //changed
  dark: "#3c3c44",
  contrastText: "#44d3b4", //changed
};

const theme_black = {
  light: "#2c2c2c",
  main: "#000000",
  dark: "#000000",
  contrastText: "#ffffff",
};

const theme_orange = {
  light: "#ff935f",
  main: "#fc6132",
  dark: "#c22d01",
  contrastText: "#FFFFFF",
};

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButton: {
      // The properties to apply
      //variant: 'contained'
    },
  },
  palette: {
    primary: is_prod ? theme_blue : theme_orange,
    secondary: theme_black,
    orange: theme_orange,
  },
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.olympicophthalmics.com/">
        Olympic Ophthalmics
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export class SignIn extends Component {
    constructor(props) {
        super(props)
        this._validAuthStates = ['signIn', 'signedOut', 'signedUp']
        this.signIn = this.signIn.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleFormSubmission = this.handleFormSubmission.bind(this)
        this.state = {}
        this.state = {
            name: "React",
            showSignIn: true,
            showHideDemo2: false
          };
        this.hideComponent = this.hideComponent.bind(this);
        this.hideSignIn = this.hideSignIn.bind(this);
    }

    handleFormSubmission(evt) {
        evt.preventDefault()
        this.signIn()
    }
    
    async signIn() {
        var username = ''
        try {
            const username = this.inputs.username
            const password = this.inputs.password
            await Auth.signIn(username, password)
         // this.props.onStateChange('signedIn', {})
        } catch (err) {
            if (err.code === 'UserNotConfirmedException') {
                this.props.updateUsername(username)
                await Auth.resendSignUp(username)
                this.props.onStateChange('confirmSignUp', {})
                alert("Error: Your account must be approved before you can log in.")
            } else if (err.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
                this.setState({error: 'Login failed.'})
                console.log(err)
                alert("Error: Incorrect Password")
            } else if (err.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                this.setState({error: 'Login failed.'})
                alert("Error: User does not exist")
            } else {
                this.setState({error: 'An error has occurred.'})
                console.log(err)
                alert("Error. Please try again.")
            }
        }
    }
    
    handleInputChange(evt) {
        this.inputs = this.inputs || {}
        const {name, value, type, checked} = evt.target
        const check_type = ['radio', 'checkbox'].includes(type)
        this.inputs[name] = check_type ? checked : value
        this.inputs['checkedValue'] = check_type ? value : null
        this.setState({error: ''})
    }    
    
    hideComponent(name) {
        console.log(name)
        switch (name) {
            case "showSignIn":
                this.setState({ showSignIn: !this.state.showSignIn })
                break
            case "showSignUp":
                this.setState({ showSignUp: !this.state.showSignUp })
                break
            default:
                this.setState({ showSignIn: !this.state.showSignIn })
                break
        }
    }

    hideSignIn() {
        this.setState({ showSignIn: !this.state.showSignIn })
        this.setState({ showSignUp: !this.state.showSignUp })
    }

    render() {
        const { showSignIn, showSignUp} = this.state;
        if (this.props.authState === 'signedIn'){
            return null
        }
        else{
          return (
            <div>
              {showSignIn && (
               <ThemeProvider theme={theme}>
               <Grid container direction="column" alignItems="center">
                 <CssBaseline />
                 <div>
                   <Grid item>
                     <Typography component="h1" variant="h5" align="center">
                     Olympic Ophthalmics Admin Dashboard         
                     </Typography>
                   </Grid>
                   <Grid item align="center">
                     <Avatar>
                       <LockOutlinedIcon />
                     </Avatar>
                   </Grid>
                   <Grid item>
                     <form noValidate>
                       <TextField
                         variant="outlined"
                         margin="normal"
                         required
                         fullWidth
                         id="username"
                         label="Email Address"
                         name="username"
                         autoComplete="email"
                         autoFocus
                         onChange={this.handleInputChange}
                       />
                       <TextField
                         variant="outlined"
                         margin="normal"
                         required
                         fullWidth
                         name="password"
                         label="Password"
                         type="password"
                         id="password"
                         autoComplete="current-password"
                         onChange={this.handleInputChange}
                       />
                       <Button
                         type="submit"
                         fullWidth
                         variant="contained"
                         color="primary"
                         onClick={this.handleFormSubmission}
                       >
                         Sign In
                       </Button>
                       <Box mt={1}>
                         <Button
                           type="submit"
                           fullWidth
                           color="primary"
                           onClick={this.hideSignIn}
                         >
                           Don't have an account? Sign Up
                       </Button>
                       </Box>
                       <Box>
                         <Button
                           type="button"
                           fullWidth
                           color="primary"
                           onClick={""}
                         >
                           Forgot Password?
                         </Button>
                       </Box>
                     </form>
                   </Grid>
                 </div>
                 <Box mt={8}>
                   <Copyright />
                 </Box>
               </Grid>
             </ThemeProvider>)}
             {showSignUp&& <CustomSignUp /> }
            </div>
          );
        }
    }
}
