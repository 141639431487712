import React, {Component} from 'react'
import App from './InternalApp'

export class AppWrapper extends Component {
  render() {
    if (this.props.authState === 'signedIn') {
      return (
        <>
          <App/>
        </>
      )
    } else {
      return null
    }
  }
}