import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {SignIn} from './SignIn'
const is_prod = process.env.REACT_APP_IS_PROD === 'true';

const theme_blue = {
  light: "#ffffff",
  main: "#2c2c34", //changed
  dark: "#3c3c44",
  contrastText: "#44d3b4", //changed
};

const theme_black = {
  light: "#2c2c2c",
  main: "#000000",
  dark: "#000000",
  contrastText: "#ffffff",
};

const theme_orange = {
  light: "#ff935f",
  main: "#fc6132",
  dark: "#c22d01",
  contrastText: "#FFFFFF",
};

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButton: {
      // The properties to apply
      //variant: 'contained'
    },
  },
  palette: {
    primary: is_prod ? theme_blue : theme_orange,
    secondary: theme_black,
    orange: theme_orange,
  },
});

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.olympicophthalmics.com/">
          Olympic Ophthalmics
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  
const CustomSignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [waitingForCode, setWaitingForCode] = useState(false);
  const [login, setLogin] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [code, setCode] = useState("");
  const [ completeSignupRequest, setCompleteSignupRequest ] = useState(false);

  useEffect(() => {
    if(!completeSignupRequest){
      return;
    }

    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      
      const confirmResponse = Auth.confirmSignUp(completeSignupRequest.email, completeSignupRequest.code)
      setWaitingForCode(false);
      setEmail("");
      setCode("");
      setLogin(true);
      setShowLogin(true);
      console.log(confirmResponse)

      const roleResponse = await fetch(`${apiUrl}/confirmsignup/${completeSignupRequest.username}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({patient: true}),
      });
      
      console.log(roleResponse);

      setCompleteSignupRequest(null);
    }

    fetchData();
  }, [completeSignupRequest]);

  const signUp = (e) => {
    e.preventDefault();
    
    Auth.signUp({ username: email, password, attributes: { email } })
      .then((data) => {
        console.log(data);
        setWaitingForCode(true);
        setPassword("");
      })
      .catch((err) => {
        console.log(err);
        if(err.code === 'UsernameExistsException'){
            alert("Error: User already exists.")
        }
        else if(err.code === 'InvalidParameterException'){
          alert("Error. Your password must be at least 8 characters long.")
        }
        else{
          alert("Error. Please try again.")
        }

      });
  };

  const resendCode = () => {
    Auth.resendSignUp(email)
      .then(() => {
        console.log("code resent successfully");
        alert("Code resent successfully.")
      })
      .catch((e) => {
        console.log(e);
        alert("Error. Please try again.")
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="column" alignItems="center">
        <CssBaseline />
        <div>
          <Grid item>
            {!waitingForCode && !showLogin && (
              <form noValidate>
                <Grid item>
                  <Typography component="h1" variant="h5" align="center">  
                    Sign Up
                  </Typography>
                </Grid>
                <Grid item align="center">
                  <Avatar>
                    <LockOutlinedIcon />
                  </Avatar>
                </Grid>
                <TextField
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                  id="sign-up-email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                  id="sign-up-password"
                  label="Password"
                  name="password"
                  autoComplete="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={signUp}
                >
                  Sign Up
                </Button>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            )}
            {waitingForCode && !showLogin && (
              <form noValidate>
                <Grid item>
                  <Typography component="h1" variant="h5" align="center">  
                    Sign Up
                 </Typography>
                </Grid>
                <Grid item align="center">
                  <Avatar>
                    <LockOutlinedIcon />
                  </Avatar>
                </Grid>
                <TextField
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                  id="sign-up-code"
                  label="code"
                  name="code"
                  autoComplete="code"
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <Box mb={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setCompleteSignupRequest({
                        email: email,
                        code: code,
                        username: email
                      })
                    }}
                  >
                     Confirm Sign Up
                  </Button>
                </Box>
                <Box>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={resendCode}
                  >
                    Resend code
                  </Button>
                </Box>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>

            )}
          </Grid>
        </div>
      </Grid>
      {login && (<SignIn />)}
    </ThemeProvider>
  );
};

export default CustomSignUp;