import {
    Box,
    Grid,
    Typography,
    Container,
  } from "@material-ui/core";
  import makeStyles from "@material-ui/core/styles/makeStyles";
  import 'date-fns';
  import React, { useEffect, useState } from "react";
  import { Loading } from ".";
  import Table from '@material-ui/core/Table';
  import TableBody from '@material-ui/core/TableBody';
  import TableCell from '@material-ui/core/TableCell';
  import TableContainer from '@material-ui/core/TableContainer';
  import TableHead from '@material-ui/core/TableHead';
  import TableRow from '@material-ui/core/TableRow';
  import Paper from '@material-ui/core/Paper';
  import { useParams } from "react-router-dom";
  import DateFnsUtils from '@date-io/date-fns';
  import {
    KeyboardDatePicker, MuiPickersUtilsProvider
  } from '@material-ui/pickers';

  const useStyles = makeStyles((theme) => {
    return {
      container: {
        marginBottom: theme.spacing(2),
      },
      table: {
          minWidth: 650,
      },
    };
  });
    
  export default function Prescriptions() {
    const classes = useStyles();
    const [ updatePrescriptionRequest, setUpdatePrescriptionRequest ] = useState(null);
    const [data, setData] = useState("");
    const [gotData, setGotData] = useState(false);
    let { userId } = useParams();  
    
    const fetchPrescriptionData = async() =>
    {
      const apiUrl = process.env.REACT_APP_API_URL;
      const prescriptionResponse = await fetch(`${apiUrl}/prescription/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      return prescriptionResponse.json();
    }

    useEffect(() => {
      fetchPrescriptionData() 
        .then((result)=> {
          setData(result)
          setGotData(true)  
        });
  
    }, []);
   
    useEffect(() => {
      if(!updatePrescriptionRequest){
        return;
      }
    
        const fetchData = async () => {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(`${apiUrl}/prescription/${userId}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              prescription: updatePrescriptionRequest.authorizedUntil,
              deviceID: updatePrescriptionRequest.deviceID
            }),
          });
          
          console.log(response);
    
          setUpdatePrescriptionRequest(null);
        }
        
        fetchData()
          .then(()=> {
            fetchPrescriptionData() 
             .then((result)=> {
               setData(result)
               setGotData(true)  
              });
          });    
    }, [updatePrescriptionRequest]);

      if ((!gotData || updatePrescriptionRequest )) {
        return <Loading />;
      }
  
      let sortedData = data.sort((a, b) => {
        return new Date(a).getTime() - new Date(b).getTime();
      });
  
      sortedData = sortedData.map((session) => {
        let startDate = new Date(session.prescription);
        let prescription_device = session.device;
  
        return {
          date: startDate.toDateString(),
          endTime: startDate.toLocaleTimeString(),
          device: prescription_device,
        };
      });
  
      return (
        <Container className={classes.container}>
          <Typography variant="h4">Prescription History: {userId}</Typography>
           &nbsp;
          <Grid container spacing={1}>
          <Grid item xs={2}>
            <Typography variant="h5">Add a new prescription: </Typography>
          </Grid>
          <Grid item xs={10}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin='none'
                id={`date-picker-dialog-${userId.username}`}
                label="Prescription End Date"
                format="MM/dd/yyyy"
                value={userId.authorizedUntil || null}
                onChange={(newDate) => {
                  console.log(newDate);
                  setUpdatePrescriptionRequest({
                    username: userId.username,
                    authorizedUntil: newDate.toISOString(),
                    deviceID: userId.device || null
                  });
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <Box style={{height: '40px'}}/>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{fontWeight: 'bold'}}>Prescription Date</TableCell>
                    <TableCell align="center" style={{fontWeight: 'bold'}}>Prescription Time</TableCell>
                    <TableCell align="center" style={{fontWeight: 'bold'}}>Device</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row) => (
                    <TableRow>
                      <TableCell align="center">{row.date}</TableCell>
                      <TableCell align="center">{row.endTime}</TableCell>
                      <TableCell align="center">{row.device}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            &nbsp;
          </Grid>
          </Grid>
        </Container>
      );
    }