import { useEffect, useState } from "react";

export const useFetch = (apiPath, requestCount = 1) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!apiPath) return;
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;

      try {
        const response = await fetch(`${apiUrl}${apiPath}`, {

        });

        const responseData = await response.json();

        if (responseData.status === "Unauthorized") {
          throw new Error("Unauthorized");
        }

        setData(responseData);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [apiPath, requestCount]);

  return { data, error };
};
