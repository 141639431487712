import React, { useState } from "react";
import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const Manufacturing = withAuth0(() => {
  const classes = useStyles();
  const [ connected, setConnect ] = useState(false);
  const [data, setData] = useState(false)
  const [serNum, setSerNum] = useState(null);
  const [aeskey] = useState(random128Hex());

  async function updateDatabase() {
    if(serNum){
      if ((0 < serNum) && (serNum < 4294967296)){
        const fetchData = async () => {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(`${apiUrl}/newsernum/${serNum}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({key: aeskey.toString()}),
          })
          return response.json();
        }
        fetchData();
        setData(true);
      }
      else {
        alert("Error: Serial number value out of range.")
      }
    }
    else {
      alert("Error: Enter a Serial Number.")
    }

  };

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function random128Hex() {
    function random16Hex() { return (0x10000 | Math.random() * 0x10000).toString(16).substr(1); }
    return random16Hex() + random16Hex() 
     + random16Hex() + random16Hex()
     + random16Hex() + random16Hex() 
     + random16Hex() + random16Hex();
  }

  const configureDevice = async () => {
    if(!connected){
      try {
        let port = await navigator.serial.requestPort({ filters: [] });
        await port.open({baudRate: 9600});
        let info = port.getInfo();
        console.log(port)
        console.log(info)
        setConnect(true);

        let validSernum = true;
        let validAeskey = true;
        const writer = port.writable.getWriter();
        const encoder = new TextEncoder();
        console.log(serNum);
        console.log(aeskey);

        //Set the serial number
        var serCmd = "serial " + serNum + "\r";
        var splitSernum = serCmd.split("");
        console.log(splitSernum)
        var i = 0;
        for (i = 0; i < serCmd.length; i++) {
          writer.write(encoder.encode(splitSernum[i]));
          await sleep(1);
        }
        //Read the result
        let reader = port.readable.getReader();
        const decoder = new TextDecoder();
        await reader.read().then(({value})=> {
          let result = (decoder.decode(value)).toString();
          console.log(result)
        });
      
        //Verify the serial number
        var checkSer = "serial\r"
        var splitCheckSer = checkSer.split("");
        for (i = 0; i < checkSer.length; i++) {
          writer.write(encoder.encode(splitCheckSer[i]));
          await sleep(1);
        } 
        await reader.read().then(({value})=> {
          const decoder = new TextDecoder();
          let result = (decoder.decode(value)).toString();
          console.log(result)
          result = result.split("serial").pop(); 
          result = (result.split("iTEAR>"));
          var resSernum = result[0].trim();

          if (resSernum === serNum) {
           console.log("serial verified")
          }
          else{
            console.log("serial not verified")
            console.log(resSernum)
            console.log(serNum)
            validSernum = false;
          }
        });

        //Set the aeskey
        var aeskeyCmd = "aeskey " + aeskey + "\r";
        var splitAeskey = aeskeyCmd.split("");
        console.log(splitAeskey)
        for (i = 0; i < aeskeyCmd.length; i++) {
          writer.write(encoder.encode(splitAeskey[i]));
          await sleep(1);
        }
        await sleep(100);
        //Read the result
        await reader.read().then(({value})=> {
          let result = (decoder.decode(value)).toString();
          console.log(result)
        });
        //Verify the aesky
        var checkAesKey = "aeskey\r";
        var splitCheckAesKey = checkAesKey.split("");
        for (i = 0; i < checkAesKey.length; i++) {
          writer.write(encoder.encode(splitCheckAesKey[i]));
          await sleep(10);
        } 
        await sleep(100);
        await reader.read().then(({value})=> {
          let result = (decoder.decode(value)).toString();
          console.log(result)
          result = result.split("aeskey").pop(); 
          result = (result.split("iTEAR>"));
          var resAesKey = result[0].trim();

          if (resAesKey === aeskey) {
           console.log("Aeskey verified")
          }
          else{
            console.log("Aeskey not verified")
            console.log(resAesKey)
            console.log(aeskey)
            validAeskey = false;
          }
        });

        //If serial number & aesky are valid, send the lock command
        if(validSernum && validAeskey){
          var lockCmd = "lock 1\r";
          var splitLock = lockCmd.split("");
          console.log(splitLock)
          for (i = 0; i < lockCmd.length; i++) {
            writer.write(encoder.encode(splitLock[i]));
            await sleep(1);
          }
          //Report to the browser that there was a success
          if (window.confirm("Device successfully programmed. Would you like to set up another device?")) {
            window.location.reload();
          }
        }
        else {
          if (window.confirm("Device could not be programmed, refresh the browser and try again.")) {
            window.location.reload();
          }
        }
      }
      catch (err) {
        alert(err)
      }
    }
   };

  return (
      <>
        <Container className={classes.container}>
          <Typography variant="h4">Program Device</Typography>
          &nbsp;
            <Grid container spacing={1}>
              <Grid item xs={2} id="NPI">
                <TextField 
                  id="standard-basic"
                  label="Serial Number"
                  onChange={(e) => setSerNum(e.target.value)} />    
              </Grid>
              <Grid item style={{textAlign: 'right'}} xs={1}>
                <Button 
                  variant='contained' 
                  style={{marginTop: '16px'}} 
                  onClick={() => updateDatabase()}>
                  Provision
                </Button>
              </Grid>
            </Grid>
            {data && 
              <Button 
                variant='contained' 
                style={{marginTop: '16px'}} 
                onClick={() => {configureDevice()}}>
                Program Device
              </Button>
            }
          </Container>
      </>
    );
  }
);

export default Manufacturing;
