import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core";

class Color extends Component {
  render() {
    const { theme, color, children } = this.props;
    return React.cloneElement(children, {
      style: {
        ...children.props.style,
        color: theme.palette[color].contrastText,
        background: theme.palette[color].main,
      },
    });
  }
}

Color.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(Color);
