import React from "react";

import { Patients } from "../components";

const Home = () => (
  <>
    <Patients />
  </>
);

export default Home;
