/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:f0fe74ab-082a-4c4d-9abe-4acbf8b29a7b",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_5LcoQchhf",
    "aws_user_pools_web_client_id": "1ekuo7e0crb1gqu9q7o346tulu",
    "oauth": {}
};


export default awsmobile;
