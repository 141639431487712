import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryDevtools } from "react-query-devtools";
import "./index.css";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
const queryCache = new QueryCache();

ReactDOM.render(
  <Router>
    <ReactQueryCacheProvider queryCache={queryCache}>
        <App />
      <ReactQueryDevtools />
    </ReactQueryCacheProvider>
  </Router>,
  document.getElementById("root")
);
