import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { CircularProgress } from "@material-ui/core";

const Loading = () => (
  <Dialog
    open={true}
    disableBackdropClick={true}
    BackdropProps={{ invisible: true }}
  >
    <DialogTitle style={{ textAlign: "center" }}>
      <img
        src={process.env.PUBLIC_URL + "/logo.png"}
        style={{ width: "10vw" }}
        alt=""
      />
    </DialogTitle>
    <DialogContent style={{ textAlign: "center", overflow: "hidden" }}>
      <CircularProgress size="10vw" color="secondary" />
    </DialogContent>
  </Dialog>
);

export default Loading;
