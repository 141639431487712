import { Button, withTheme } from "@material-ui/core";
import React from "react";
import Color from "./color";

const ColorButton = (props) => (
  <Color color={props.color}>
    <Button
      {...props}
      color="primary"
    />
  </Color>
);

export default withTheme(ColorButton);
