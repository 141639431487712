import React, {useEffect, useState } from 'react';
import { Switch, Route} from "react-router-dom";
import Container from "@material-ui/core/Container";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Doctors, Footer, Loading} from "./components";
import Admins from "./components/admins";
import Manufacturing from "./components/manufacturing";
import Home from "./views/home";
import Profile from "./components/profile"
import { Auth } from 'aws-amplify';
import awsconfig from "./aws-exports";
import Amplify from "aws-amplify";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Sessions from './components/sessions';
import Prescriptions from './components/prescriptions';

Amplify.configure(awsconfig);

const is_prod = process.env.REACT_APP_IS_PROD === 'true';

const theme_blue = {
  light: "#ffffff",
  main: "#2c2c34", //changed
  dark: "#3c3c44",
  contrastText: "#44d3b4", //changed
};

const theme_black = {
  light: "#2c2c2c",
  main: "#000000",
  dark: "#000000",
  contrastText: "#ffffff",
};

const theme_orange = {
  light: "#ff935f",
  main: "#fc6132",
  dark: "#c22d01",
  contrastText: "#FFFFFF",
};

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButton: {
      // The properties to apply
      //variant: 'contained'
    },
  },
  palette: {
    primary: is_prod ? theme_blue : theme_orange,
    secondary: theme_black,
    orange: theme_orange,
  },
});

export default function App() {
    const [userRole, setUserRole] = useState(null);  
    const [navOpen, setNavOpen] = useState(false);
    let username = (Auth.user.username).toString();
    
    async function signOut() {
      Auth.signOut({ global: true }); 
    };
    
    async function deleteAccount() {
      const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const npiResponse = await fetch(`${apiUrl}/deleteaccount/${username}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
        return npiResponse.json();
      }
      if (window.confirm("Are you sure you'd like to delete your account?")) {
        Auth.currentAuthenticatedUser({ 
          bypassCache: true // Optional, By default is false. 
        }).then((user) => { 
          fetchData()
          .then(() => {
            user.deleteUser((error, data) => { 
              if (error) { throw error; } 
              // Do something to delete user data in your system 
              // Log the user out 
              Auth.signOut({ global: true }); 
         });
        });
     
     }).catch(err => console.log(err));
      }
    };

    useEffect(() => {
      let role = (Auth.user.signInUserSession.idToken.payload["cognito:groups"]).toString();
      setUserRole(role);
    }, []);

    if(!userRole){
      // Loading
  
      return (
        <ThemeProvider theme={theme}>
          <Loading />
        </ThemeProvider>
      );
    }
  
    if (userRole !== "Doctor" && userRole !== "Admin"){
      return(
        <ThemeProvider theme={theme}>
          <Dialog
            open={true}
            disableBackdropClick={true}
            BackdropProps={{ invisible: true }}
          >
            <DialogTitle style={{ textAlign: "center" }}>
              <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                style={{ width: "10vw" }}
                alt=""
              />
            </DialogTitle>
            <DialogContent style={{ textAlign: "center", overflow: "hidden" }}>
              Error: Patients must use mobile app
              <br/>
              <br/>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={signOut}
              >
                Sign Out
              </Button>
            </DialogContent>
          </Dialog>
        </ThemeProvider>
      );   
    }

    var findAdmin = userRole.indexOf("Admin");
    var routes;
    
    if(findAdmin !== -1){
      routes = {
        "/": { title: "Patients", component: Home, exact: true },
        "/doctors": { title: "Doctors", component: Doctors, exact: true },
        "/admins": { title: "Admins", component: Admins, exact: true },
        "/manufacturing": { title: "Manufacturing", component: Manufacturing, exact: true },
      };
    }
    else {
      routes = {
        "/": { title: "Patients", component: Home, exact: true },
        "/profile": { title: "Profile", component: Profile, exact: true},
      };
    }

  
    return (
      <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Toolbar>
            {/* on smaller screens show page title and a menu for nav */}
            <Box
              display={{ xs: "inherit", md: "none" }}
              style={{ alignItems: "center" }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  setNavOpen(true);
                }}
              >
                <MenuIcon />
              </IconButton>
              <img
                src={
                  process.env.PUBLIC_URL + "/logo.png"
                }
                style={{ height: "36px" }}
                alt=""
              />
              &nbsp; &nbsp;
              <Typography variant="h6">
                {/* { routes[this.props.location.pathname].title } */}
              </Typography>
              <Drawer
                anchor="left"
                open={navOpen}
                onClose={() => {
                  setNavOpen(false);
                }}
              >
                <List
                  onClick={() => {
                    setNavOpen(false);
                  }}
                  onKeyDown={() => {
                    setNavOpen(false);
                  }}
                >
                  {Object.entries(routes).map(([path, val]) => {
                    return (
                      <ListItem
                        button
                        key={path}
                        component={RouterLink}
                        to={path}
                      >
                        <ListItemText primary={val.title} />
                      </ListItem>
                    );
                  })}
                </List>
              </Drawer>
            </Box>
  
            {/* show links in header on larger screens */}
            <Box
              display={{ xs: "none", md: "inherit" }}
              style={{ alignItems: "center" }}
            >
              <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                style={{ height: "30px" }}
                alt=""
              />
              &nbsp;
              <img
                src={
                  process.env.PUBLIC_URL + "/Olympic.Wordmark.png"
                }
                style={{ height: "30px" }}
                alt=""
              />
              &nbsp; &nbsp;
              {Object.entries(routes).map(([path, val]) => {
                return (
                  <Button
                    color="inherit"
                    component={RouterLink}
                    to={path}
                    key={path}
                  >
                    {val.title}
                  </Button>
                );
              })}
            </Box>
            <div style={{ flexGrow: 1 }} />
            <div style={{ flexGrow: 1 }} />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={signOut}
            >
              Sign Out
            </Button>
          </Toolbar>
        </AppBar>
        <Container className="flex-grow-1 mt-5" style={{marginTop: '10px'}}>
          <Switch>
            {Object.entries(routes).map(([path, val]) => {
              var Component = val.component;
              return (
                <Route
                  path={path}
                  render={() => <Component />}
                  exact={val.exact}
                  key={path}
                />
              );
            })}
            <Route path="/sessions/:userId" component={Sessions} />
            <Route path="/prescriptions/:userId" component={Prescriptions} />
          </Switch>
        </Container>
        <Footer />
        <AppBar position="bottom">
          <Toolbar>    
            <Typography>Welcome, {username}!</Typography>
            <div style={{ flexGrow: 1 }} />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={deleteAccount}
            >
              Delete Account
            </Button>
          </Toolbar>
        </AppBar>

      </ThemeProvider> 
    );
  }