import React, { useEffect, useState } from "react";
import { Loading } from ".";
import { useFetch } from "../utils";
import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
  ButtonGroup
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
//import OverviewStats from "./overview-stat";
import awsconfig from "../aws-exports";
import Amplify from "aws-amplify";

Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const Doctors = withAuth0(() => {
  const classes = useStyles();

  const [ requestNumber, setRequestNumber ] = useState(0);

  const { data, error } = useFetch(`/doctors`, requestNumber);

  const [ makeRoleRequest, setRoleRequest ] = useState(null);

  useEffect(() => {
    if(!makeRoleRequest){
      return;
    }

    const fetchData = async () => {
      let changeRole;
      let newRole;
      const apiUrl = process.env.REACT_APP_API_URL;

      if (makeRoleRequest.role === "Admin"){
        console.log("set role request: " + makeRoleRequest.role)
        changeRole = "admins";
        newRole = {admin: true};
      }
      else{
        console.log("set role request: " + makeRoleRequest.role)
        changeRole = "patients";
        newRole = {patient: true};
      }

      const response = await fetch(`${apiUrl}/${changeRole}/${makeRoleRequest.username}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newRole),
      });
      
      console.log(response);

      setRoleRequest(null);
      setRequestNumber(r => r + 1);
    }

    fetchData();
  }, [makeRoleRequest]);

  if ((!data && !error) || makeRoleRequest) {
    return <Loading />;
  }

  return (
      <>
        <Container className={classes.container}>
          <Typography variant="h4">Doctors</Typography>
          &nbsp;
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h5">
                Doctor E-mail
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h5">
                Account Created
              </Typography>
            </Grid>
            
            {data && data.sort((a, b) => a.email - b.email).map(admin => {
              return <React.Fragment key={admin.email}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    {admin.email}
                  </Typography>
                </Grid>
                <Grid item xs={4}>{admin.created}</Grid>
                <Grid item xs={0.5} style={{textAlign: 'right'}}>
                  <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button onClick={() => {
                      setRoleRequest({
                        username: admin.username,
                        role: "Admin"
                      })
                    }}>
                      Make Admin
                    </Button>
                    <Button onClick={() => {
                      setRoleRequest({
                        username: admin.username,
                        role: "Patient"
                      })
                    }}>
                      Make Patient
                    </Button>
                  </ButtonGroup>
                </Grid>
              </React.Fragment>;
            })}
          </Grid>
        </Container>
      </>
    );
  }
);

export default Doctors;
