import {
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React from "react";
import { Loading } from ".";
import { useFetch } from "../utils";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
  };
});
  
export default function Sessions() {
    const classes = useStyles();

    let { userId } = useParams();

    console.log(userId);

    const { data, error } = useFetch(`/history/${userId}`, 0);

    if ((!data && !error)) {
      return <Loading />;
    }

    let sortedData = data.sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    sortedData = sortedData.map((session) => {
      let startDate = new Date(session.startTime);
      let stopDate = new Date(session.stopTime);

      let secondsDuration = Math.round((stopDate - startDate) / 1000);

      let durationString = `${Math.floor(secondsDuration/60)}m ${secondsDuration%60}s`;

      return {
        date: startDate.toDateString(),
        startTime: startDate.toLocaleTimeString(),
        duration: durationString,
      };
    });

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Session History: {userId}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box style={{height: '40px'}}/>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Date</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Start Time</TableCell>
                  <TableCell align="center" style={{fontWeight: 'bold'}}>Duration</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((row) => (
                  <TableRow>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">{row.startTime}</TableCell>
                    <TableCell align="center">{row.duration}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }